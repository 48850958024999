* {
  font-family: "Quicksand", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  user-select: none;
}
/* SCROLLBAR */
::-webkit-scrollbar {
  display: none;
}

.leaflet-container {
  width: 100%;
  height: 75vh;
}

.dark-map {
  filter: var(--map-tiles-filter, none);
}

button {
  outline: none;
}

/* body {
  overflow-x: hidden;
  overflow-y: hidden;
} */

.text-blue {
  color: #4cbcec !important;
}

.text-warning {
  color: #ec9a3c !important;
}

.text-primary {
  color: #4c90f0 !important;
}

.bg-alt-light {
  background-color: #eff2f5 !important;
}

.bg-alt-light-nav {
  background-color: #eff2f5e3 !important;
}

.bg-alt-dark {
  background-color: #1c2025 !important;
}

.bg-alt-dark-nav {
  background-color: #181b20e3 !important;
}

.bg-alt-mid {
  background-color: #f9fafb !important;
}

.button-icon {
  align-self: center;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
}

.button-header-container {
  margin-left: auto !important;
}

.timer {
  display: none;
}

.drizzlr-modal {
  max-height: 35rem !important;
  overflow-y: auto !important;
}

.card-header {
  display: flex !important;
  border-bottom: 1px solid #d3d8de;
  margin: -20px -20px 10px -20px;
  padding: 5px 10px 5px 10px;
  font-size: 1.1rem;
  align-items: center;
  border-radius: 12px 12px 0 0;
}

.link-hover:hover {
  background-color: #eee;
  transition: all 0.75s;
  color: #3e3e3e;
}

.boxshadow {
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 0px 0.2rem 0.3rem 0.1rem rgba(0, 0, 0, 0.1);
}

.overlay-div {
  background-color: rgba(79, 97, 116, 0.518);
  width: 28rem;
  max-width: 60%;
  height: 32rem;
  padding: 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  color: white !important;
}

.overlay-div > svg {
  fill: white !important;
}

#overlay-inner {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

#overlay {
  backdrop-filter: blur(10px);
}

.outline {
  background-color: none;
  border: 0.2rem;
}

.no-select {
  user-select: none;
}

.no-pointer:active {
  pointer-events: none;
}

.bg-blur {
  backdrop-filter: blur(1px) opacity(0.7);
}

main {
  margin-top: 70px;
  margin-bottom: 50px;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-temp {
  font-size: 3rem;
  font-family: "Quicksand";
}

.main-title {
  display: flex;
  align-items: center;
}

.main-icon {
  width: 5rem;
  filter: drop-shadow(0px 1px 2px rgb(0 0 0 / 0.2));
}

.main-condition {
  display: flex;
  justify-content: center;
  font-size: 1.4rem;
  font-family: "Quicksand";
  font-weight: 600;
  color: #4cbcec;
}

.main-alt-temps {
  text-align: center;
  font-size: 1.25rem;
}

/* WIND */
.wind-direction {
  font-size: 1.5rem;
  color: #4cbcec;
  display: flex;
}

.wind-arrow {
  fill: #4cbcec !important;
  height: 1.5rem !important;
  width: 1.5rem !important;
  margin-right: 0.5rem;
  transition: 10s;
}

.wind-contents {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.wind-speed {
  margin-left: 1rem;
  font-size: 1.5rem;
}

.wind-speed-units {
  color: gray;
  font-size: 1.2rem;
}

.wind-speed-units.small {
  font-size: 0.8rem;
}

@media only screen and (min-width: 768px) {
  .wind-arrow {
    width: 3rem !important;
    height: 3rem !important;
    margin-right: 1rem !important;
  }
  .wind-direction {
    font-size: 3rem;
  }
  .wind-speed {
    font-size: 3rem;
    margin-left: 1.5rem;
  }
  .wind-speed-units {
    font-size: 1.25rem;
  }
  .wind-speed-units.small {
    font-size: 1rem;
  }
  .wind-contents {
    margin-top: 3rem;
  }
}

.arrow-wnw {
  transform: rotate(22.5deg);
}
.arrow-nw {
  transform: rotate(45deg);
}
.arrow-nnw {
  transform: rotate(67.5deg);
}
.arrow-n {
  transform: rotate(90deg);
}
.arrow-nne {
  transform: rotate(112.5deg);
}
.arrow-ne {
  transform: rotate(135deg);
}
.arrow-ene {
  transform: rotate(157.5deg);
}
.arrow-e {
  transform: rotate(180deg);
}
.arrow-ese {
  transform: rotate(-157.5deg);
}
.arrow-se {
  transform: rotate(-135deg);
}
.arrow-sse {
  transform: rotate(-112deg);
}
.arrow-s {
  transform: rotate(-90deg);
}
.arrow-ssw {
  transform: rotate(-67.5deg);
}
.arrow-sw {
  transform: rotate(-45deg);
}
.arrow-wsw {
  transform: rotate(-22.5deg);
}
/* .arrow-w {
  transform: rotate(180deg);
} */

.wiggle-slow {
  animation: wiggle-medium 3s;
  animation-iteration-count: infinite;
}
.wiggle-medium {
  animation: wiggle-medium 1s;
  animation-iteration-count: infinite;
}
.wiggle-fast {
  animation: wiggle-medium 0.3s;
  animation-iteration-count: infinite;
}

@keyframes wiggle-medium {
  0% {
    transform: rotate(-1deg);
  }
  10% {
    transform: rotate(-2deg);
  }
  40% {
    transform: rotate(1deg);
  }
  70% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(1deg);
  }
}

/* HEADER */

.menubar {
  margin: -8px -8px 0px -8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  position: fixed;
  top: 0;
  z-index: 3;
  height: 2.5rem;
  width: 100%;
}

.menu-items {
  display: flex;
  flex-flow: column wrap;
  align-content: flex-start;
}

.menu-units {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin: 0.5rem 0 0.5rem 0;
}

.logo-container {
  display: flex;
  margin-right: auto;
  align-items: center;
  cursor: pointer;
}

.logo-container-overlay {
  display: flex;
  width: fit-content;
  justify-content: center !important;
  align-items: center;
  padding: 1rem;
  margin: 0 auto 0 auto;
}

.logo {
  font-size: 1.6rem;
  font-family: "Quicksand";
  font-weight: 700;
  user-select: none;
}

.logo:hover span {
  opacity: 1;
}

.logo-icon {
  margin-right: 0.7rem;
}

/* PAGE NAV */
.page-nav {
  position: fixed;
  display: flex;
  align-items: center;
  top: 4rem;
  z-index: 2;
  height: 2.5rem;
  width: 100%;
  margin: 0 0 0 -0.5rem;
  padding: 0.5rem;
  backdrop-filter: blur(4px);
  justify-content: center;
  /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); */
}

.back-button {
  display: flex;
  margin-right: auto;
}

/* DRAWER */

.menu-drawer {
  z-index: 9998;
}

/* FOOTER */
.footerbar {
  margin: -8px -8px 0px -8px;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 1rem;
}

.version {
  font-size: medium;
  font-family: "Quicksand";
  font-weight: 700;
  margin-right: auto;
  user-select: none;
}

.privacy {
  font-size: medium;
  font-family: "Quicksand";
  font-weight: 700;
  margin-right: 1rem;
  user-select: none;
}

.text-hidden {
  opacity: 0;
  transition: 0.3s;
}

.version:hover span {
  opacity: 1;
}

.version-link {
  color: gray !important;
}

.version-link:hover {
  text-decoration: none;
}

/* Leaflet */
#map {
  height: 180px;
}

/* PAGES */

.page-container {
  margin-top: 8rem;
  /* background-color: blue; */
}

.page-home {
  display: flex;
  flex-direction: row;
}

.page-daily {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.page-hourly {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-title-container {
  display: flex;
  flex-direction: column;
  margin-right: auto;
}

.page-title {
  text-align: center;
  font-size: 1.25rem;
}

.page-sub-title {
  text-align: center;
  font-size: 1rem;
}

@media only screen and (min-width: 768px) {
  .page-title-container {
    flex-direction: row;
    margin-right: auto;
    align-items: center;
  }

  .page-title {
    font-size: 2rem;
  }

  .page-sub-title {
    margin-left: 0.5rem;
    font-size: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .page-home {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .page-daily {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* ALERTS */

.alerts-div {
  margin-left: 1rem;
  justify-content: center !important;
  cursor: pointer;
}

.alerts-icon {
  height: 1.5rem !important;
  width: 1.5rem !important;
  animation-name: orangepulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: ease;
  transition: 0.2s !important;
}

.alerts-icon:hover {
  height: 1.6rem !important;
  width: 1.6rem !important;
  /* filter: drop-shadow(0px 2px 6px rgb(0 0 0 / 0.2)); */
}

@keyframes orangepulse {
  from {
  }
  to {
    fill: orange;
  }
}

.daily-contents {
  display: flex;
  justify-content: center;
}

.day-card {
  margin: 0.5rem !important;
  padding: 0.7rem !important;
  font-size: 0.8rem;
  width: 3rem !important;
}

.day-page-card {
  margin: 2rem !important;
  padding: 2rem !important;
  max-width: 500px !important;
  width: 20rem !important;
}

.day-card:hover {
  /* filter: drop-shadow(0px 1px 4px rgb(0 0 0 / 0.2)); */
  border: 1px solid #4cbcec !important;
}

.day-temp {
  font-weight: 900;
  font-size: 0.85rem !important;
}
.day-page-temp {
  font-weight: 900;
  font-size: 1.5rem !important;
  padding: 0.25rem;
}

.day-page-temp-container {
  border-radius: 1rem;
  width: fit-content;
  min-width: 16rem;
  margin: auto;
  padding: 0.5rem 0 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.day-day {
  text-align: center;
  font-size: 1.2rem;
  font-variant: small-caps;
}

.day-page-day {
  text-align: start;
  font-size: 2rem;
  font-variant: small-caps;
  display: flex;
  align-items: center;
  margin-top: -1rem;
}

.day-page-date {
  font-size: 1.2rem !important;
  margin-left: 1rem;
  margin-right: -1rem;
  color: gray;
}

.day-desc {
  font-size: 0.65rem;
  text-align: center;
  margin-bottom: 0.5rem;
  color: gray;
}

.day-page-desc {
  margin-top: -0.75rem;
  margin-bottom: 1rem;
  color: #4cbcec;
}

.day-wind-units {
  font-size: 0.5rem;
  color: gray;
}
.day-page-wind-units {
  font-size: 0.8rem;
  color: gray;
}

.day-page-tertiary {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  margin: 1rem 0 1rem 0;
  padding: 0.5rem 0.25rem 0.5rem 0.25rem;
  border-radius: 1rem;
}

.day-icon {
  width: 3rem;
  /* margin: 0 10% 0 10%; */
  filter: drop-shadow(0px 1px 2px rgb(0 0 0 / 0.2));
}

.day-page-icon {
  width: 6rem;
  margin: 0 10% 0 10%;
  filter: drop-shadow(0px 1px 2px rgba(255, 255, 255, 0.6)) !important;
}

.day-little-icon {
  margin-right: 0.25rem;
}

.more-button {
  display: flex;
  justify-content: right;
}

.attribute {
  color: gray;
  font-size: 0.5rem;
  cursor: pointer;
}

.day-details-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  gap: 2rem;
  padding: 10px 0 50px 0;
}

.day-details-card {
  width: 100% !important;
  max-width: 30rem;
  margin: 0px -2px 0px -2px !important;
}

.wind-day-direction {
  /* font-size: 1.5rem; */
  /* color: #4cbcec; */
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

/* HOURLY */

.hour-card {
  padding: 0.7rem !important;
  font-size: 0.8rem;
  margin: 0.5rem 0 0.5rem 0 !important;
  width: 100% !important;
  max-width: 14rem;
  height: 2rem;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.hour-page-card {
  padding: 1rem !important;
  font-size: 1.2rem;
  margin: 0.75rem 0 0.75rem 0 !important;
  width: 30rem !important;
  max-width: 85%;
  height: fit-content;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.hourly-contents {
  margin: 0 -0.5rem -0.5rem -0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hourly-card-hour {
  font-size: 1.1rem;
}

.hourly-page-hour {
  font-size: 1.5rem;
}

.hourly-card-temps {
  display: flex;
  flex-direction: column;
}

.hourly-page-temps {
  display: flex;
  flex-direction: column;
}

.hour-icon {
  height: 5rem;
  filter: drop-shadow(0px 1px 2px rgb(0 0 0 / 0.2));
}

.hour-daybreak {
  width: 100%;
  max-width: 30rem;
}

.prompt {
  border: 1px solid;
  border-radius: 10px;
  display: block;
  margin: 20px;
  padding: 0;
  pointer-events: all;
  transition: 0.2s;
  width: 100%;
  max-width: 24rem;
}

.prompt-dark {
  background-color: #1c2127;
  border-color: #2f343c;
  box-shadow: 0 8px 24px rgb(0 0 0 / 40%);
}

.prompt-light {
  background-color: #f6f7f9;
  border-color: #dce0e5;
  box-shadow: 0 8px 24px rgb(0 0 0 / 20%);
}

.prompt-container {
  align-items: center;
  bottom: 0;
  justify-content: center;
  left: 0;
  overflow: hidden;
  pointer-events: all;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.2s;
  z-index: 11;
  backdrop-filter: blur(10px);
}
